<template>
  <div class="list-grid" v-class-mod:list-grid="[`columns-${columns ?? 0}`, { hideDisabled }]">
    <span
      v-for="item in list"
      :tabindex="isDisabled(item.date) ? -1 : 0"
      class="list-grid__item"
      @click="select(item)"
      @keydown.enter="select(item)"
      :key="item.id"
      v-class-mod:list-grid__item="{
        isDisabled: isDisabled(item.date),
        isActive: isActive(item.date),
        isToday: isToday(item.date),
      }"
    >
      {{ item.label }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { DateTime, getTime, isSameMonth, isSameYear, Month, setMonth, setYear, Year } from "@horizon56/time";

import { Select, ViewSelect } from "../date-picker.vue";

type InputItem = { id: any; label: string; date: DateTime };
const props = defineProps<{
  date: DateTime;
  list: InputItem[];
  viewSelect: ViewSelect;
  select: Select;
  hideDisabled?: boolean;
  columns?: number;
  min?: DateTime;
  max?: DateTime;
}>();

const emit = defineEmits<{ (e: "update", a: DateTime): void }>();

const isDisabled = (date: DateTime) => {
  if (props.min && date.isBefore(props.min, props.viewSelect)) {
    return true;
  }
  if (props.max && date.isAfter(props.max, props.viewSelect)) {
    return true;
  }

  return false;
};

const isActive = (date: DateTime) => {
  if (props.viewSelect !== props.select) {
    return false;
  }
  if (props.viewSelect === "month") {
    return isSameMonth(props.date, date);
  } else if (props.viewSelect === "year") {
    return isSameYear(props.date, date);
  }
};

const isToday = (date: DateTime) => {
  if (props.viewSelect === "month") {
    return isSameMonth(getTime(true), date);
  } else if (props.viewSelect === "year") {
    return isSameYear(getTime(true), date);
  }
};

const select = (item: InputItem) => {
  if (!isDisabled(item.date)) {
    if (props.viewSelect === "month") {
      emit(
        "update",
        setMonth(setYear(props.date, item.date.get("year") as Year), (item.date.get("month") + 1) as Month),
      );
    } else if (props.viewSelect === "year") {
      emit("update", setYear(props.date, item.date.get("year") as Year));
    }
  }
};
</script>

<style lang="scss" scoped>
.list-grid {
  display: grid;
  max-height: 160px;
  overflow: auto;
  gap: 10px;
  @include set-scrollbar;
  @for $i from 1 through 6 {
    &--columns-#{$i} {
      grid-template-columns: repeat($i, auto);
    }
  }
  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: var(--app-button-height-medium);
    line-height: var(--app-button-height-medium);
    padding: 0 7px;
    border: 3px solid transparent;
    border-radius: var(--app-radius-large);
    @include focus-outline;
    &:not(#{&}--isDisabled):not(#{&}--isActive) {
      cursor: pointer;
      &:hover {
        color: var(--black-90);
        background: var(--black-5);
      }
    }
    &--isActive {
      cursor: pointer;
      color: var(--static-white);
      background: var(--green-500);
    }
    &--isDisabled {
      opacity: 0.3;
      pointer-events: none;
    }
    &--isToday {
      border-color: var(--green-500);
    }
  }
  &--hideDisabled #{&}__item--isDisabled {
    display: none;
  }
}
</style>
