<template>
  <div v-if="viewDate" class="date-picker">
    <title-banner v-if="viewSelect === 'year'" :title="'Year'" />
    <date-banner
      v-else-if="viewSelect === 'month'"
      :view-date="viewDate"
      :type="'year'"
      :has-title-action="true"
      :min="min"
      :max="max"
      @update="(d) => (viewDate = d)"
      @click:title="() => (viewSelect = 'year')"
    />
    <date-banner
      v-else
      :view-date="viewDate"
      :type="'month'"
      :min="min"
      :max="max"
      :has-title-action="true"
      @update="(d) => (viewDate = d)"
      @click:title="() => (viewSelect = 'month')"
    />
    <div class="date-picker__day-panel">
      <list-grid
        v-if="date && viewSelect === 'year'"
        :date="date"
        :view-date="viewDate"
        :view-select="'year'"
        :select="select"
        :list="availableYears"
        :hide-disabled="true"
        :columns="4"
        :max="max"
        :min="min"
        @update="
          (d) => {
            if (select === 'year') {
              $emit('update:date', d);
            } else {
              viewSelect = 'month';
              viewDate = d;
            }
          }
        "
      />
      <list-grid
        v-else-if="date && viewSelect === 'month'"
        :date="date"
        :view-date="viewDate"
        :view-select="'month'"
        :select="select"
        :list="availableMonths"
        :columns="3"
        :max="max"
        :min="min"
        @update="
          (d) => {
            if (select === 'month') {
              $emit('update:date', d);
            } else {
              viewSelect = 'date';
              viewDate = d;
            }
          }
        "
      />
      <template v-else>
        <week-days :view-date="viewDate" />
        <date-grid
          :select="select"
          :view-date="viewDate"
          :selected-date="selectedDate"
          :min="min"
          :max="max"
          @update:date="(e) => $emit('update:date', e)"
          @update:range="(e) => $emit('update:range', e)"
          @update:week="(e) => $emit('update:week', e)"
          ref="gridElem"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";

import { DateTime, DurationObject, getMonths, getNoon, getTime, getYears } from "@horizon56/time";

import DateBanner from "./components/date-banner.vue";
import DateGrid from "./components/date-grid.vue";
import ListGrid from "./components/list-grid.vue";
import TitleBanner from "./components/title-banner.vue";
import WeekDays from "./components/week-days.vue";

export type Select = "date" | "week" | "month" | "year" | "range";

export type ViewSelect = "date" | "month" | "year";

const props = defineProps<{
  date?: DateTime;
  from?: DateTime;
  to?: DateTime;
  min?: DateTime;
  max?: DateTime;
  select: Select;
}>();

defineEmits<{
  (e: "update:date", arg: DateTime): void;
  (e: "update:week", a: DurationObject): void;
  (e: "update:range", a: DurationObject): void;
  (e: "update:select", a: Select): void;
}>();

const viewDate = ref<DateTime>();

const viewSelect = ref<ViewSelect>();

const gridElem = ref<InstanceType<typeof DateGrid>>();

const selectedDate = computed(() => {
  if (props.date) {
    return props.date;
  } else if (props.from || props.to) {
    return { from: props.from, to: props.to };
  }
  return undefined;
});

const availableYears = computed(() =>
  getYears().map(({ year, label, date }) => ({
    id: year,
    label,
    date,
  })),
);

const availableMonths = computed(() => {
  if (!viewDate.value) {
    return [];
  }
  return getMonths(viewDate.value).map(({ month, label, date }) => ({
    id: month,
    label,
    date,
  }));
});

onMounted(async () => {
  const time = props.date || getTime(true);
  if (time) {
    viewDate.value = getNoon(time);
  }
});

defineExpose({
  setFocus: () => gridElem.value?.setFocus(),
});

watch(
  () => props.date,
  () => {
    const time = props.date || getTime(true);
    viewSelect.value = props.select === "year" ? "year" : props.select === "month" ? "month" : "date";
    if (time) {
      viewDate.value = getNoon(time);
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.date-picker {
  display: flex;
  flex-flow: column;
  width: 308px;
  overflow: hidden;
  color: var(--black-90);
  user-select: none;
  font-size: var(--app-font-size-base);
  background: var(--menu-bg);
  border-radius: var(--app-radius-medium);
  border: 1px solid var(--black-10);
  &__day-panel {
    padding: 12px;
    --width: 282px;
  }
}
</style>
